import React, { useState } from "react"
import { Link } from "gatsby";
import styled, { keyframes } from "styled-components";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";


const LinkBlock = styled.div`
  width: max-content;
  cursor: pointer;
  pointer-events: initial;
`;

const LinkStyled = styled(Link)`
  font-size: 14px;
  line-height: ${props => props.gold ? '26px' : '30px'};
  display: block;
  text-decoration: none;
  color: ${props => props.white ? colors.white : props.lightgrey? colors.grey : colors.darkGrey};
  ${fonts.ralewayMedium};
  letter-spacing: 0.28px;
  width: max-content;
  min-width: 36px;
`;

const ButtonStyled = styled.button`
  font-size: 14px;
  line-height: 26px;
  line-height: ${props => props.gold ? '26px' : '30px'};
  display: block;
  text-decoration: none;
  color: ${props => props.white ? colors.white : props.lightgrey? colors.grey : colors.darkGrey};
  ${fonts.ralewayMedium};
  letter-spacing: 0.28px;
  width: max-content;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;


const Line = styled.div`
  display: block;
  width: 36px;
  border-bottom: ${props => props.gold ? `1px solid ${colors.lightGold}` : props.white? `1px solid ${colors.white}` : `1px solid ${colors.darkGrey}`};
  transition: .2s;
  margin-left: auto;
  margin-right: auto;
  //width: ${props => props.hovered ? '0px' : '36px'};
`;

const UnderlineButton = ({children, white, gold, to, lightGrey, type}) => {
  const [isHovered, setIsHovered] = useState(null);

  return (
    <LinkBlock>
      {to ?
        <LinkStyled to={to} white={white} gold={gold} lightgrey={lightGrey ? 1 : 0}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(null)}>
          {children}
        </LinkStyled>
        :
        <ButtonStyled white={white} gold={gold} lightgrey={lightGrey ? 1: 0} type={type}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(null)}>
          {children}
        </ButtonStyled>
      }
      <Line hovered={isHovered} gold={gold} white={white}/>
    </LinkBlock>
  )
}

export default UnderlineButton
